<template>
  <div
    class="avatar-wrapper"
    :style="{
      background: backgroundColor ?? avatar.background_color,
      width: size + 'px',
      height: size + 'px',
    }"
  >
    <div
      v-html="baseShow.src"
      class="base-image"
      :style="{ ...baseShow.position, fill: avatar.skin_tone }"
    />
    <div
      v-html="shirt.src"
      class="shirt-image"
      :style="{ ...shirt.position, fill: shirtColor ?? avatar.shirt_color }"
    />
    <div
      v-if="shouldShowThird"
      v-html="faceShow.src"
      class="face-image"
      :style="{ ...faceShow.position, background: avatar.skin_tone }"
    />
    <div
      v-if="hairShow && shouldShowThird"
      v-html="hairShow.src"
      class="hair-image"
      :style="{ ...hairShow.position, fill: avatar.hair_color }"
    />
    <div
      v-if="facialHairShow && shouldShowThird"
      v-html="facialHairShow.src"
      class="facial-hair-image aligner"
      :style="{ ...facialHairShow.position, fill: avatar.facial_hair_color }"
    />
    <!-- <div v-if="accessoriesShow && shouldShowThird">
      <div
        v-for="accessory in accessoriesShow"
        :key="accessory.id"
        v-html="accessory.src"
        class="accessories-image aligner"
        :style="{...accessory.position, fill: avatar.accessories.color}"
      />
    </div> -->
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import bases from "@/data/avatar/avatarBases";
import shirt from "@/data/avatar/avatarShirt";
import faces from "@/data/avatar/avatarFaces";
import hairs from "@/data/avatar/avatarHairs";
import facialHairs from "@/data/avatar/avatarFacialHairs";
import accessories from "@/data/avatar/avatarAccessories";

const props = defineProps({
  backgroundColor: {
    type: String,
  },
  size: {
    type: [Number, String],
    required: true,
  },
  avatar: {
    type: Object,
    required: true,
  },
  base: {
    type: Object,
  },
  shirtColor: {
    type: String,
  },
  skinTone: {
    type: String,
  },
  shouldShowThird: {
    type: Boolean,
    default: false,
  },
});

const baseShow = computed(() => {
  return props.base
    ? props.base
    : props.avatar?.base
    ? bases.find((base) => base.id === props.avatar.base)
    : bases[0];
});
const faceShow = computed(() =>
  faces.find((face) => face.id === props.avatar.face_type)
);
const hairShow = computed(() =>
  hairs.find((hair) => hair.id === props.avatar.hair_type)
);
const facialHairShow = computed(() =>
  facialHairs.find(
    (facialHair) => facialHair.id === props.avatar.facial_hair_type
  )
);
// const accessoriesShow = computed(() => accessories.filter(accessory => props.avatar.accessories.type?.includes(accessory.id)))
</script>

<style scoped>
.avatar-wrapper {
  position: relative;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.base-image,
.shirt-image,
.face-image,
.hair-image,
.facial-hair-image,
.accessories-image {
  position: absolute;
}

.base-image {
  width: 55%;
  z-index: 1;
  transition: all 0.2s ease;
}

.shirt-image {
  width: 70%;
  transition: all 0.2s ease;
}

.face-image {
  fill: transparent;
  width: 30%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  z-index: 3;
}

.hair-image {
  z-index: 5;
}

.facial-hair-image {
  z-index: 4;
}

.accessories-image {
  z-index: 4;
}

.aligner {
  display: flex;
}
</style>
